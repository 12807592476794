import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import {
    createStyles,
    DialogContent,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "../../../../lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { getOrganisationId } from "lib/utils/common_utils";
import { WorkshopDashboardServices } from "lib/services/api/dashboard/workshop";
import TabDetails from "views/Operations/Workshop/TabDetails";

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "800px",
            },
        },
        container: {
            // minWidth: "700px",
            // minHeight: "400px",
            padding: "50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
    })
);

export default function StoppedOrders(props) {
    const { open, handleClose, stoppedOrder, handleCloseAction, order_type_name, currentSelections, tabID, getDetails, selectedBase } = props;
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [pages, setPages] = useState(0);
    const [totalCounts, setTotalCounts] = useState(5);
    const [pageSizes, setPageSizes] = useState(5);
    const [stoppedOrders, setStoppedOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewAction, setViewAction] = useState(false);
    const [currentSelection, setCurrentSelection] = useState({});

    const tableOptions = {
        ...options,
        page: pages,
        total: totalCounts,
        pageSize: pageSizes,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };

    const getStoppedOrders = () => {
        const params = {
            order_type: order_type_name,
            graph: currentSelections?.graph,
            key: currentSelections?.key,
            page: pages + 1,
            limit: pageSizes,
            organisation_id: getOrganisationId()
        }
        setLoading(true)
        selectedBase.forEach((item, index) => params[`base[${[index]}]`] = item)
        WorkshopDashboardServices.getSelectedOrders(params)
            .then((res) => {
                if (res) {
                    setStoppedOrders(res.data);
                    setTotalCounts(res.total_count);
                }
            })
            .catch(() => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })
    };

    useEffect(() => {
        getStoppedOrders()
    }, [pages, pageSizes, currentSelections, selectedBase])


    const column = [
        {
            title: "Order #",
            field: "order_number",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "10px",
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "10px",
            },
        },
        {
            title: "Customer",
            field: "customer",
            width: "20%",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "20%",
            },
        },

        {
            title: "Description",
            field: "description",
            headerStyle: {
                width: "40%",
                textAlign: "left"
            },
            cellStyle: {
                width: "40%",
                textAlign: "left"
            }
        }, {
            title: "Position",
            field: "approval_number",

        },
        {
            title: "Action",
            render: (rowData) => (
                <IconButton
                    onClick={() => {
                        setViewAction(true);
                        setCurrentSelection(rowData)
                    }}
                >
                    <ViewIcon
                        fontSize="small"
                        htmlColor={ICON_COLOUR}
                    />
                </IconButton>
            ),
        },
    ];


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseAction}
                className={classes.modal}
                maxWidth={"sm"}
            >
                <div className={classes.container}>
                    <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginBottom: "15px" }}
                        className={classes.popupTitle}
                    >
                        Selected Workshop Orders
                    </Typography>

                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                minWidth: "700px",
                            }}
                            isLoading={loading}
                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={stoppedOrders}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: "No data Found",
                                },
                                header: {
                                    actions: "",
                                },
                            }}
                            onChangePage={(page) => {
                                setPages(page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                setPageSizes(pageSize);
                            }}
                            totalCount={totalCounts}
                            page={pages}
                        />
                    </Grid>

                </div>
            </Dialog>
            {viewAction &&
                <TabDetails
                    permission={props?.permission}
                    handleClose={handleCloseAction}
                    action={"Unlock"}
                    currentSelection={currentSelection}
                    tabId={tabID}
                    order_type={order_type_name}
                    stoppageOrderClose={handleCloseAction}
                    getMaintenanceOrderList={getDetails}
                    type={currentSelections?.graph === "open_commercial_positions" ? "dashboardCommercial" : ""}

                />}
        </div>
    );
}
